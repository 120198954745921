import "../assets/css/LandingPage.css";

import wednesday from "../assets/media/wednesday.png";
import mynote from "../assets/media/mynote.png";
import colors from "../assets/media/colors.png";
import notesoverlap from "../assets/media/notesoverlap.png";
import MoonToggle from "../components/MoonToggle";
import Login from "../components/Login";
import Alert from "../components/Alert";

function LandingPage(props) {
  return (
    <>
           {
            props.isError?
            <Alert onClose={props.setIsError}> 
                <p>Something went wrong, please try again later.</p>
                <small>Tap to dismiss this alert.</small>
            </Alert>
            :null
          }
      <div className="landing-container">
        <MoonToggle />
        <div className="lbox">
          <svg
            className="stack"
            width="302"
            height="329"
            viewBox="0 0 302 329"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="8.45782"
              y="177.006"
              width="138"
              height="188"
              rx="18"
              transform="rotate(-40.3888 8.45782 177.006)"
              fill="#F6A89E"
              stroke="#33322E"
              stroke-width="12"
            />
            <rect
              x="61.45"
              y="87.6386"
              width="138"
              height="188"
              rx="18"
              transform="rotate(-22.7249 61.45 87.6386)"
              fill="#8CD4CB"
              stroke="#33322E"
              stroke-width="12"
            />
            <rect
              x="136.422"
              y="22.1952"
              width="138"
              height="188"
              rx="18"
              transform="rotate(-6.47285 136.422 22.1952)"
              fill="#F4D799"
              stroke="#33322E"
              stroke-width="12"
            />
            <path
              d="M183 108.584L208.837 134.476L250 73.4761"
              stroke="#33322E"
              stroke-width="16"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div className="text">
            <h1 className="notefy-heading">notefy</h1>
            <p className="tagline">you note, we'll notify :)</p>
            <p className="crafted">
              crafted by{" "}
              <a href="https://github.com/Servatom/">
                <span>servatom</span>
                <svg
                  className="githublogo"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                </svg>
              </a>
            </p>
          </div>
        </div>
        <div className="rbox">
          <div className="left">
            <img className="wednesday" src={wednesday} />
            <img src={mynote} />
          </div>
          <div className="right">
            <img src={colors} className="color-image" />
            <img src={notesoverlap} className="notes-image" />
          </div>
        </div>
      </div>
      <Login />
    </>
  );
}

export default LandingPage;
